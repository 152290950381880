<template>
  <div
    class="page initiatives"
    :loaded="Boolean(initiatives) && Boolean(page)"
    :dialog="Boolean(selectedInitiative)"
  >
    <section class="top-section" v-if="page">
      <div class="wrapper">
        <div class="container" :style="{ backgroundImage: `url(${page.image})` }">
          <div class="content">
            <h1 v-html="page.title.replace(/\n/g, '<br>')"></h1>
            <h3 v-html="page.subtitle.replace(/\n/g, '<br>')"></h3>
          </div>
        </div>
      </div>
    </section>

    <section class="section" v-if="page">
      <div class="wrapper">
        <div class="section-footer">
          <p v-html="page.description.replace(/\n/g, '<br>')"></p>
        </div>
      </div>
    </section>

    <section class="section sectors">
      <div class="wrapper">
        <div class="sector">
          <div class="container">
            <div
              class="initiative"
              v-for="initiative in initiatives"
              :key="initiative.id"
            >
              <div class="initiative-cover">
                <img v-if="initiative.background" :alt="initiative.name" :src="initiative.background">
              </div>
              <div class="initiative-image-container">
                <img :src="initiative.image" :alt="initiative.name" class="initiative-image">
              </div>
              <div class="initiative-content">
                <h5>{{initiative.name}}</h5>
                <div class="initiative-description" v-html="getExcerpt(initiative.description)"></div>
                <div class="buttons">
                  <a class="btn medium ghost" :href="formatLink(initiative.link)" target="_blank">
                    Visit website
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <ContactForm />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Icon from "./Icon.vue";
import Dropdown from "./Dropdown.vue";
import ContactForm from "./ContactForm.vue";

export default {
  name: "Hubs",
  components: {
    Icon,
    Dropdown,
    ContactForm,
  },
  data() {
    return {
      initiatives: [],
      page: null,
      selectedInitiative: null,
      selectedSector: null,
      selectedTime: null,
    };
  },
  computed: {
    db() {
      return this.$store.state.firebase.firestore;
    },
    ...mapGetters({
      testMode: "getTest",
    }),
  },
  watch: {
    testMode() {
      this.getItems();
    },
  },
  methods: {
    getExcerpt(text) {
      if (!text) {
        return '';
      }
      return text.replace(/\n/g, '<br>');
    },
    formatLink(link) {
      return /^https?:\/\//.test(link) ? link : `http://${link}`;
    },
    getItems() {
      if (this.initiativesRef) this.initiativesRef();
      if (this.pageRef) this.pageRef();
      this.pageRef = this.db()
        .doc(`${this.testMode ? "test_" : ""}pages/hubs`)
        .onSnapshot((settings) => {
          this.page = settings.data();
        });
      this.initiativesRef = this.db()
        .collection(`${this.testMode ? "test_" : ""}hubs`)
        .orderBy("order")
        .onSnapshot((initiatives) => {
          this.initiatives = initiatives.docs.map(doc => doc.data());
        });
    },
  },
  mounted: function () {
    this.getItems();
  },
  beforeDestroy: function () {
    if (this.pageRef) this.pageRef();
    if (this.initiativesRef) this.initiativesRef();
  },
};
</script>

<style scoped>
.section {
  margin-bottom: 168px;
}

.section-title {
  position: relative;
  margin-bottom: 48px;
  padding: 0 96px;
}
.section-title.centered {
  text-align: center;
}
.section-title h3 {
  font-size: 4rem;
  line-height: 1.166666;
  color: var(--Metallic-Seaweed);
}
.section-title h5 {
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 1.166666;
  margin-top: 16px;
}

.section-image {
  position: relative;
  padding-top: 33.42%;
  margin-bottom: 40px;
}
.section-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}

.section-footer {
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  padding: 0 96px;
}
.section-footer p::after {
  content: '';
  display: block;
  width: 587px;
  max-width: 100%;
  height: 3px;
  border-radius: 2px;
  background: linear-gradient(90deg, #0A7E8C 0%, #12E5FF 100%);
  margin: 32px auto 40px;
}

.top-section {
  margin-bottom: 96px;
}
.top-section .container {
  position: relative;
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center right;
  background-image: url(../assets/images/wb_bg_services-header.png);
  border-radius: 12px;
  overflow: hidden;
  padding-top: 53.4%;
}
.top-section .container .content {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  padding: 64px 88px;
  border-radius: 12px;
  background: linear-gradient(36deg, rgba(10, 126, 140, 0.80) 13.82%, rgba(10, 126, 140, 0.50) 32.99%, rgba(10, 126, 140, 0.00) 64.59%);
}
.top-section .container .content .logo {
  display: block;
  width: 178px;
  position: absolute;
  top: 64px;
  left: 96px;
}
.top-section .container .content h1 {
  font-size: 8rem;
  line-height: 0.86;
  margin-bottom: 16px;
}
.top-section .container .content h3 {
  font-size: 4rem;
  line-height: 1.1625;
}
.top-section .container .content p {
  font-size: 1.125rem;
}

.text-section .text-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0 48px;
}
.text-section .text-container .text-aside {
  width: 42%;
  padding: 0 48px;
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 1.16666666;
}
.text-section .text-container .text-paragraph {
  width: 58%;
  padding: 0 48px;
  font-size: 1.5rem;
  font-weight: 500;
}
.text-section .text-container .text-paragraph p::after {
  content: '';
  display: block;
  height: 3px;
  border-radius: 2px;
  background: linear-gradient(90deg, #0A7E8C 0%, #12E5FF 100%);
  margin: 40px 0;
}

.sector h4 {
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 40px;
}

.selectors {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 64px;
  font-weight: 600;
}

.selectors .selector {
  display: flex;
  align-items: center;
  width: 200px;
  height: 96px;
  padding: 0 32px;
}

.sector .container {
  display: flex;
  flex-wrap: wrap;
  gap: 72px 32px;
}

.sector .initiative {
  display: flex;
  flex-direction: column;
  width: calc(33.3333333% - 22px);
  border-radius: 12px;
  transition: all .2s ease-out;
}

#app[no-touch] .sector .initiative:hover {
  box-shadow: 0px 24px 32px 0px rgba(14, 58, 90, 0.08);
  transform: translateY(-8px);
}

.sector .initiative h5 {
  font-size: 1.5rem;
  margin-bottom: 16px;
  text-align: center;
}
.sector .initiative h5::after {
  content: '';
  display: block;
  height: 3px;
  border-radius: 2px;
  background: linear-gradient(90deg, #0A7E8C 0%, #12E5FF 100%);
  margin-top: 16px;
}

.sector .initiative .initiative-content {
  padding: 0 32px 32px;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.sector .initiative .initiative-cover {
  width: 100%;
  height: 278px;
  background: #f2f2f2;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.sector .initiative .initiative-cover img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.sector .initiative .initiative-image-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 196px;
  height: 196px;
  border-radius: 50%;
  margin: -98px auto 24px;
  background: #fff;
  padding: 16px;
}

.sector .initiative .initiative-image-container img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 50%;
}

.sector .initiative .initiative-description {
  font-size: 1.25rem;
  text-align: center;
  flex: 1;
}

.sector .initiative .buttons {
  text-align: center;
  margin-top: 40px;
}

.overlay {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 64px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 9999;
  background: rgba(255, 255, 255, 0.8);
  opacity: 0;
  pointer-events: none;
  transition: opacity .2s ease-in;
}

.overlay[visible] {
  opacity: 1;
  pointer-events: all;
}

.overlay .modal {
  background: #fff;
  border-radius: 16px;
  border: 1px solid rgba(101, 102, 98, 0.08);
  box-shadow: 0 16px 24px rgba(52, 52, 52, 0.08);
  width: 100%;
  max-width: 1024px;
  padding: 64px 96px;
}

.overlay .modal .modal-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.overlay .modal .modal-header img {
  height: 100px;
}

.overlay .modal .modal-header a {
  color: #0A7E8C;
}

.overlay .modal .company-info {
  margin: 24px 0;
}

.overlay .modal .partner-logos {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  margin: 0 24px;
}

.overlay .modal .partner-logo {
  margin-left: 16px;
}

.overlay .modal .partner-logos img {
  display: block;
  height: 64px;
}

@media screen and (max-width: 1280px) {
  .sector .initiative {
    width: calc(50% - 16px);
    margin-bottom: 48px;
  }
  .top-section .container .content {
    padding: 48px 64px;
  }
  .top-section .container .content h3 {
    font-size: 3.75rem;
  }
}

@media screen and (max-width: 1024px) {
  .page-header .wrapper {
    padding: 0 64px;
  }
  .page-header h3, .page-header h5, .page-header p {
    max-width: 480px;
  }
  .overlay {
    align-items: flex-start;
    padding: 24px;
  }
  .overlay .modal {
    padding: 24px;
  }
  .top-section .container {
    flex-direction: column-reverse;
    margin-right: 0;
  }
  .top-section .section-image {
    position: relative;
    height: 360px;
    right: 0;
    width: calc(100% - 96px);
    top: 0;
    margin: 0 48px;
  }
  .top-section .container .content {
    width: auto;
  }
  .top-section .container .content h3 {
    font-size: 3.25rem;
  }
}

@media screen and (max-width: 880px) {
  .page-header .wrapper {
    background: none;
  }
  .page-header h3, .page-header h5, .page-header p {
    max-width: none;
  }
}

@media screen and (max-width: 800px) {
  .sector .container {
    flex-direction: column;
    align-items: center;
  }
  .sector .initiative {
    width: 100%;
    max-width: 440px;
    margin: 0 0 24px;
  }
  .top-section .container .content {
    padding: 24px 24px 0;
  }
  .top-section .section-image {
    width: calc(100% - 48px);
    margin: 0 24px;
  }
  .top-section .container {
    padding-top: 24px;
  }
}

@media screen and (max-width: 520px) {
  .page-header .wrapper {
    padding: 0 32px;
  }
  .selectors .selector {
    width: auto;
    padding: 0 16px;
  }
  .sector .initiative .initiative-cover {
    height: 240px;
  }
  .sector .initiative .initiative-image-container {
    width: 152px;
    height: 152px;
    margin-top: -76px;
  }
  .sector .initiative .initiative-description {
    font-size: 1rem;
  }
}

@media screen and (max-width: 1280px) {
  .section {
    margin-bottom: 96px;
  }
  .section-title {
    padding: 0 64px;
  }
  .section-title h3 {
    font-size: 3rem;
  }
  .top-section .container {
    min-height: 400px;
  }
  .top-section .container .content {
    padding: 48px 64px;
  }
  .top-section .container .content .logo {
    width: 200px;
    left: 64px;
    top: 48px;
  }
  .top-section .container .content h1 {
    font-size: 6rem;
  }
  .top-section .container .content h3 {
    font-size: 3rem;
  }
  .text-section .text-container {
    padding: 0 32px;
  }
  .text-section .text-container .text-aside,
  .text-section .text-container .text-paragraph {
    padding: 0 32px;
  }
  .text-section .text-container .text-aside {
    font-size: 1.75rem;
  }
  .text-section .text-container .text-paragraph {
    font-size: 1.3125rem;
  }
}

@media screen and (max-width: 880px) {
  .section {
    margin-bottom: 96px;
  }
  .section-title {
    padding: 0 48px;
  }
  .section-title h3 {
    font-size: 2.5rem;
  }
  .section-title h5 {
    font-size: 1.5rem;
  }
  .section-footer {
    padding: 0;
  }
  .top-section {
    margin-bottom: 48px;
  }
  .top-section .container .content {
    padding: 48px;
  }
  .top-section .container .content .logo {
    width: 170px;
    left: 48px;
    top: 48px;
  }
  .top-section .container .content h1 {
    font-size: 4.5rem;
  }
  .top-section .container .content h3 {
    font-size: 2rem;
  }
  .text-section .text-container {
    display: block;
    padding: 0 24px;
  }
  .text-section .text-container .text-aside,
  .text-section .text-container .text-paragraph {
    width: auto;
  }
  .text-section .text-container .text-aside {
    margin-bottom: 24px;
  }
}

@media screen and (max-width: 580px) {
  .section-title {
    padding: 0;
  }
  .section-footer {
    font-size: 1.3125rem;
  }
  .top-section .container .content {
    padding: 32px;
  }
  .top-section .container .content h1 {
    font-size: 3rem;
  }
  .top-section .container .content h3 {
    font-size: 2rem;
  }
  .text-section .text-container {
    padding: 0;
  }
  .text-section .text-container .text-aside,
  .text-section .text-container .text-paragraph {
    padding: 0;
  }
  .text-section .text-container .text-aside {
    font-size: 1.3125rem;
  }
  .text-section .text-container .text-paragraph {
    font-size: 1.125rem;
  }
}
</style>
